/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.mat-sort-header-button { text-align:left;}
html, body { height: 100%; }
body {     background-color: #f7f8fb; margin: 0; font-family: 'Poppins', sans-serif; font-weight: 400; font-size: 16px; }

h2{
  font-size: 48px;
  text-transform: capitalize;
  color: #000;
  font-weight: 500;
}
h3{
  font-size: 28px;
  color: #000;
}
h4{
  font-size: 22px;
  color: #000;
}
h5 {
  font-size: 18px;
  color: #000;
}
h6{
  font-size: 16px;
  color: #000;
}

.font-30{
    font-size: 30px;
}
.font-13{
    font-size: 13px;
}
.font-weight-bold {
    font-weight: 700!important;
}

.md-select-menu-container {
  z-index: 9999;
}
.upworkIcon {
  background: url(./assets/image/iconfinder_79-upwork_1929180.svg) no-repeat 0 0;
  width: 26px;
  height: 26px;
  display: inline-block;
  background-size: 100%;
}
.skypeIcon {
  background: url(./assets/image/iconfinder_icon-social-skype_211916.svg) no-repeat 0 0;
  background-size: 100%;
  width: 26px;
  height: 26px;
  display: inline-block;
}
.sectionbg{
  background:linear-gradient(50deg,#5268f2 0%,#14b0d1 100%);
  color: #FFF;
  h2, h3, h4, h5{
    color: #FFF;
  }
  &.revarbg {
    background:linear-gradient(180deg,#5268f2 0%,#14b0d1 100%);
  }
  &.sectinbefore{
    position: relative;
    z-index: 0;
    &::before{
      content: '';
      height: 200px;
      z-index: -1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 60%;
      -webkit-border-radius: 60%;
      -moz-border-radius: 60%;
      -ms-border-radius: 60%;
      -o-border-radius: 60%;
      background:linear-gradient(0deg, rgba(255,255,255,0.05) 0.14%, rgba(255,255,255,0) 50% );
}
  }
}

.darkselect .mat-form-field-flex{
  background-color: #FFFFFF80;
}

.card-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
}
table {
    width: 100%;
  }

  .progress-xs, .progress-xs .progress-bar {
    height: .25rem;
}

.loadingajax{
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
    z-index: 999;   
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  .card{
    border: solid 1px rgba(0,0,0,0.09);
    box-shadow: 2px 5px 6px #ecf1f7;
  }
  .card-header{
    border-color:rgba(0,0,0,0.09) ;
    background: #FFF;
  }
  .checkboxmiddle{margin: 0 10px;}
  .checkboxmiddle label{ margin-bottom: 0;}
  .vertical-align-mid{
    vertical-align: middle;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .cdk-overlay-pane{
    max-width: 96vw !important;
  }
  .mat-dialog-container{
    padding: 15px;
  }
  .mat-dialog-container .mat-form-field{
    width: 50%;
    padding: 0 5px;
  }
  .mat-dialog-container .mat-form-field-infix{
    width: 100%;
  }
  button{
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }
  .btn-webiberis{
    color: #FFF;
    border: solid 2px #FFF;
    background: linear-gradient(50deg,#5268f2 0%,#14b0d1 100%);;
    padding: 10px 5px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    font-size: 16px;
    text-transform: uppercase;
    min-width:180px ;
    margin: 0 auto;
    
    cursor: pointer;
    &.btn-secondary{    
      border-color: #FFF !important;
      &:hover, &:focus{
        box-shadow: none !important;
        outline: none !important;
        border-color: #FFF !important;
      }
    }
    &:hover{
        background: linear-gradient(250deg,#5268f2 0%,#14b0d1 100%);;
        border-color: #FFF;
    }
    &:focus{
      outline: none !important;
      box-shadow: none !important;
    }
    &:disabled{
      outline: none;
    }
    &.btn-secondary:hover{
      opacity: 0.9;
    }
    .material-icons{
      vertical-align: middle;
    }
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
  }

  @media screen and (max-width: 768px) {
      .not-mobile{
        display: none;
      }
      h2{
        font-size: 32px;
      }
      h3{
        font-size: 22px;
      }
      h4{
        font-size: 18px;
      }
      h5 {
        font-size: 18px;
        color: #000;
      }
  }
@media screen and (max-width: 580px) {
  .mat-dialog-container .mat-form-field{
    width: 100%;
  }
}





@page {
  size: A4;
  margin: 0;
  font-size: 16px;

}
@media print {
  html, body {
      width: 210mm;
      height: 297mm;
      font-size: 16px;
    }
    
 
  .printhide {
      display: none;
  }
  .pdfcol {page-break-after: always;
    font-size: 18px;
    padding: 40px 40px 0;
  }
}


.mat-select-panel.fulldropdone{
  position: fixed;
  top: 0;
  bottom: 20px !important;
  max-height: 80vh !important;
  width: 100%;
  height: 90vh;
  min-width: 400px !important;
  right: 0;
}
.tags-btn-row{
  display: flex;
  flex-wrap: wrap;
}
.tags-btn{
      border: solid 1px #00000020;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        padding: 0px 8px;
        margin: 0.2rem 2px;
        background-color: #FFF;
        font-size: 12px;
}